import { useEffect, useState } from "react"
import { getSeatImageUrl, tr } from "../utils"

function OrderSummaryComponent({ orderData, formatPrice, total, deposit, fulltotal, pageType }) {
	const [seatPrice, setSeatPrice] = useState(0)
	const [detailsOpen, setDetailsOpen] = useState(false)
	useEffect(() => {
		let pr = 0
		orderData.details.order_items.map((seat) => {
			pr += parseFloat(seat[2])
			return false
		})
		setSeatPrice(pr)
	}, [orderData, setSeatPrice])

	return (
		<>
			<div className="seat-summary-card seat open">
				<img
					//src="assets/TB_M04_C08.png"
					src={getSeatImageUrl(orderData)}
					alt="seat thumbnail"
					className="seat-thumbnail"
				/>
				<div className="content-text">
					<div className="features-holder">
						<table className="features-table">
							<tbody>
								<tr className="price-summary-row">
									<td className="row-description">
										<span
											className={"seat-details-toggle " + (detailsOpen ? "open" : "close")}
											onClick={() => setDetailsOpen((previousStatus) => !previousStatus)}
										>
											{" "}
											{tr("Seat price")}{" "}
										</span>
									</td>
									<td className="row-price">
										<span className="full-price">{formatPrice(seatPrice)}€ </span>
									</td>
								</tr>
								{orderData &&
									orderData.details.order_items.map((seat, index) => {
										return (
											<tr
												className={
													"price-inner-row seat-color-price-holder " +
													(detailsOpen ? "open" : "close")
												}
												key={index}
											>
												<td className="row-description">
													<span> {tr(seat[0])} </span>
												</td>
												<td className="row-price">
													<span className="full-price">{formatPrice(seat[2])}€ </span>
												</td>
											</tr>
										)
									})}

								<tr className="price-summary-row shipping">
									<td className="row-description">{tr("Shipping Cost")}</td>
									<td className="row-price">
										<span className="full-price">
											<small>{formatPrice(orderData.details.shipping_cost)}€</small>
										</span>
									</td>
								</tr>
								<tr className="_price-total-row _total-price-holder price-summary-row lined">
									<td className="row-description">
										<span className="single-price-label">{tr("Total price")}</span>
									</td>
									<td className="row-price">
										<span className="full-price">{formatPrice(fulltotal)}€</span>
									</td>
								</tr>
								<tr className="price-summary-row ">
									<td className="row-description">
										{tr("Deposit")}
										<span className="green-badge">{tr("Paid")}</span>
									</td>
									<td className="row-price">
										<span className="full-price">
											<small>{formatPrice(-deposit)}€</small>
										</span>
									</td>
								</tr>
								{(orderData.whStatus === "Fully Paid (Financed)" ||
									orderData.whStatus === "Fully Paid - Financed (Pago financiado)") && (
									<>
										<tr className="price-summary-row ">
											<td className="row-description">{tr("Amount financed")}</td>
											<td className="row-price">
												<span className="full-price">
													<small>{formatPrice(-total)}€</small>
												</span>
											</td>
										</tr>
										<tr className="total-price-holder">
											<td className="row-description">
												<span className="single-price-label">
													<b>
														{tr("Monthly Payment")} {tr("Applied")}
													</b>
												</span>
											</td>
											<td className="row-price">
												<span className="full-price">
													<b>
														{" "}
														{formatPrice(total / 6)}€ /{tr("month")}
													</b>
												</span>
											</td>
										</tr>
									</>
								)}
								{(orderData.whStatus === "Fully Paid (Balance Received)" ||
									orderData.whStatus === "Fully Paid - Balance Received (Pagado)") && (
									<>
										<tr className="price-summary-row ">
											<td className="row-description">{tr("Balance Received")}</td>
											<td className="row-price">
												<span className="full-price">
													<small>{formatPrice(-total)}€</small>
												</span>
											</td>
										</tr>
										<tr className="price-summary-row">
											<td className="row-description">
												<span className="single-price-label">{tr("Outstanding amount")}</span>
											</td>
											<td className="row-price">
												<span className="full-price">{formatPrice(0)}€</span>
											</td>
										</tr>
									</>
								)}

								{orderData.whStatus !== "Fully Paid (Financed)" &&
									orderData.whStatus !== "Fully Paid - Financed (Pago financiado)" &&
									orderData.whStatus !== "Fully Paid (Balance Received)" &&
									orderData.whStatus !== "Fully Paid - Balance Received (Pagado)" && (
										<>
											<tr className="price-summary-row">
												<td className="row-description">
													<span className="single-price-label">
														{tr("Outstanding amount")}
													</span>
												</td>
												<td className="row-price">
													<span className="full-price">{formatPrice(total)}€</span>
												</td>
											</tr>
											<tr className="total-price-holder">
												<td className="row-description">
													<span className="single-price-label">
														<b>
															{tr("Monthly Payment")}{" "}
															{pageType === "success" && tr("Applied")}
														</b>
													</span>
												</td>
												<td className="row-price">
													<span className="full-price">
														<b>
															{" "}
															{formatPrice(total / 6)}€ /{tr("month")}
														</b>
													</span>
												</td>
											</tr>
										</>
									)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<span className="vat-notice">
				{tr("All prices are VAT inclusive.")} {tr("Calculated at 21%.")}
			</span>
		</>
	)
}

export default OrderSummaryComponent
