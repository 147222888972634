import { useEffect, useState } from 'react'
import './App.css'
import HeaderComponent from './components/HeaderComponent'
import CountrySelectComponent from './components/CountrySelectComponent'
import OrderSummaryComponent from './components/OrderSummaryComponent'
import { formatPrice, getQueryVars, validateDNI, tr, getSeatImageUrl } from './utils'

const BASE_URL = process.env.REACT_APP_API_URL
const RETURN_PROFILE_URL = process.env.REACT_APP_RETURN_PROFILE_URL

function App() {
	const [orderData, setOrderData] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const [isError, setIsError] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [extraData, setExtraData] = useState({ dni: '', country: 'ES', validForm: true, formError: '' })
	const [pageType, setPageType] = useState('form')
	const [deposit, setDeposit] = useState(50)

	const [confiaMessage, setConfiaMessage] = useState('')

	const [total, setTotal] = useState(0)
	const [fulltotal, setFulltotal] = useState(0)
	useEffect(() => {
		loadOrderData()
	}, [])

	const loadOrderData = async () => {
		setIsLoading(true)
		const qvars = getQueryVars()
		if (qvars === undefined) {
			setIsLoading(false)
			setIsError(true)
			setErrorMessage(tr('No order data found in the query string'))
			return
		}

		const response = await fetch(
			`${BASE_URL}/order/verify?orderRef=${qvars['orderRef']}&orderAmount=${qvars['orderAmount']}&timeInMs=${qvars['timeInMs']}&paymentHash=${qvars['paymentHash']}`
		)
		const data = await response.json()
		if (data.data.error === 1) {
			setIsLoading(false)
			setIsError(true)
			setErrorMessage(tr(data.data.message))
			return
		}

		if (qvars['ok'] === 1 || qvars['ok'] === '1') {
			setIsLoading(false)
			setPageType('success')
		} else if (qvars['ok'] === 0 || qvars['ok'] === '0') {
			setPageType('error')
		} else {
			// all other cases
			// control order status
			switch (data.data.order.whStatus) {
				case 'Waiting for Finance (Esperando finanzas)':
					setIsLoading(false)
					setPageType('waiting')
					break
				case 'Fully Paid - Financed (Pago financiado)':
					setIsLoading(false)
					setPageType('alreadypaid')
					break
				case 'Fully Paid - Balance Received (Pagado)':
					setIsLoading(false)
					setPageType('alreadypaid')
					break
				default:
					break
			}
		}

		//console.log(data.data)
		//console.log(data.data.order)
		const depo = parseFloat(data.data.order.details.order_deposit_amount)
		setDeposit(depo)
		setOrderData(data.data.order)
		setTotal(parseFloat(data.data.order.whAmount) + parseFloat(data.data.order.details.shipping_cost) - depo)
		setFulltotal(parseFloat(data.data.order.whAmount) + parseFloat(data.data.order.details.shipping_cost))
		setIsLoading(false)
	}

	const validateForm = (event) => {
		if (event.target.name === 'dni') {
			const result = validateDNI(event.target.value)
			setExtraData({
				...extraData,
				dni: result.normalized,
				validForm: result.valid,
				type: result.type,
				formError: result.valid ? '' : tr('Invalid DNI/NIE'),
			})
		}
		if (event.target.name === 'country') {
			setExtraData({ ...extraData, country: event.target.value })
		}
	}

	const restartProcess = () => {
		window.location.href = RETURN_PROFILE_URL
	}

	const startProcess = async (event) => {
		event.preventDefault()
		setIsLoading(true)
		if (extraData.dni.length <= 5) {
			setIsLoading(false)
			setIsError(true)
			setErrorMessage(tr('Validation error: Please use a correct DNI/NIE number.'))
			return
		}
		if (extraData.country !== 'ES') {
			setIsLoading(false)
			setIsError(true)
			setErrorMessage(tr('Validation error: Financing is only available for Spanish residents.'))
			return
		}

		const qvars = getQueryVars()
		if (qvars === undefined) {
			setIsLoading(false)
			setIsError(true)
			setErrorMessage(
				tr(
					'Validation error: Wrong query string. No order data could be found in the query string. Please try accessing this site from the your order profile page.'
				)
			)
			return
		}
		const response = await fetch(
			`${BASE_URL}/order/start?orderRef=${qvars['orderRef']}&orderAmount=${orderData.amount}&paymentHash=${qvars['paymentHash']}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
				body: JSON.stringify({ dni: extraData.dni, country: extraData.country }),
			}
		)
		const responseData = await response.json()
		console.log(responseData)
		if (responseData.data && responseData.data.data !== undefined) {
			document.location = responseData.data.data
		} else {
			setConfiaMessage(responseData.data.message)
		}
		return false
	}

	const backToProfile = () => {
		window.location = RETURN_PROFILE_URL
	}

	return (
		<div className="App">
			<HeaderComponent orderData={orderData} />
			<form className="form--validate form--state-switch" id="formCheckout" action="" method="post">
				<section id="display-area" className="hide_header">
					<div className="steps-wrapper">
						<div
							id="display-step-3"
							className="display-step active"
							data-payment-mode="single"
							data-checkout-step="form"
						>
							<div className="checkout-card checkout-form">
								<div className="chechout-review-holder">
									{pageType === 'form' && (
										<>
											<img className="logo-confia" src="/images/logo-confia.svg" alt="Confia" />
											<h5>{tr('Request a payment plan')}</h5>
											<br />
											<p className="subtitle">
												{tr('To request financing, follow these steps:')}
											</p>
											<ol>
												<li>
													<strong>{tr('Before you begin,')}</strong>{' '}
													{tr(
														'make sure you have your ID card/passport and a mobile phone where you can receive SMS notifications from Confía to confirm your identity.'
													)}
												</li>
												<li>
													<strong>
														{tr(
															'Once your information has been verified and the credit has been approved'
														)}
													</strong>{' '}
													{tr(
														'you will be redirected to the payment gateway to enter your credit card details. The validation process may take up to 15 minutes.'
													)}
												</li>
												<li>
													<strong>{tr('The first of the 6 payments')}</strong>{' '}
													{tr('for the outstanding amount of the order will be charged.')}
												</li>
												<li>
													<strong>{tr('If you successfully complete the process,')}</strong>{' '}
													{tr('you will receive a confirmation email from Confia.')}
												</li>
												<li>
													<strong>{tr('If your transaction is declined')}</strong>{' '}
													{tr(
														'you can try again with different user data or choose the one-time payment option.'
													)}
												</li>
											</ol>
											<div className="checkout-message">
												<div className="checkout-message-content">
													<h6>
														{' '}
														<img
															className="checkout-message-image"
															src="/images/info-icon.svg"
															alt="Attention mark"
														/>{' '}
														{tr('Important!')}
													</h6>
													<p>
														{tr(
															'Only available for Spanish residents and for orders up to 600€ including the shipping cost.'
														)}
													</p>
													<p>
														{tr(
															'Confia might need up to 15 minutes to carry out the relevant checks.'
														)}
													</p>
													<p>
														{tr(
															'Approval depends on Confía risk calculation and is not related with Memorabilia or Real Madrid processes.'
														)}
													</p>
												</div>
											</div>
										</>
									)}
									{pageType === 'waiting' && (
										<>
											<h5>{tr('You already started your financing process')}</h5>
											<p className="subtitle">
												{tr(
													'Sorry, this order is being processed by Confía and we are still waiting for an answer. Please, wait up to 15 minutes before you try again.'
												)}
											</p>
											<p className="subtitle">
												{tr(
													'If you do not get any confirmation on the next 15 minutes, you can start over the process.'
												)}
											</p>
											<p className="centered-text">
												<button
													className="rm-button rm-button--primary rm-button--medium"
													type="button"
													onClick={backToProfile}
												>
													{tr('Go back to my profile')}
												</button>
											</p>
										</>
									)}
									{pageType === 'success' && (
										<>
											<h5 className="centered-text">{tr('Congratulations!')}</h5>
											<p className="subtitle centered-text">
												{tr('Your payment plan has been approved by')}
											</p>
											<p className="subtitle centered-text">
												<img
													className="centered-text"
													src="/images/logo-confia.svg"
													alt="Confia"
												/>
											</p>

											<p className="subtitle centered-text">
												{tr(
													'Remember your order payment status might take a few hours to be updated. Please be patient.'
												)}
											</p>
											<p className="centered-text">
												<button
													className="rm-button rm-button--primary rm-button--medium"
													type="button"
													onClick={backToProfile}
												>
													{tr('Go back to my profile')}
												</button>
											</p>
										</>
									)}
									{pageType === 'alreadypaid' && (
										<>
											<h5>{tr('Hey! this order has been approved. Nothing else to do here!')}</h5>
											<p className="subtitle">
												{tr(
													'Please check your order status in your profile page in the Memorabilia site. Keep in mind that status change might take up to 6 hours.'
												)}
											</p>
											<p className="main-thumbnail">
												<img
													//src="assets/TB_M04_C08.png"
													src={getSeatImageUrl(orderData)}
													alt="seat thumbnail"
													className="seat-thumbnail"
												/>
											</p>
											<p className="centered-text">
												<button
													className="rm-button rm-button--primary rm-button--medium"
													type="button"
													onClick={backToProfile}
												>
													{tr('Go back to my profile')}
												</button>
											</p>
										</>
									)}
									{pageType === 'error' && (
										<>
											<h5 className="centered-text">
												{tr('There was a problem on the payment')}
											</h5>
											<p className="subtitle centered-text">
												{tr('Your payment plan has been rejected by')}
											</p>
											<p className="subtitle centered-text">
												<img
													className="centered-text"
													src="/images/logo-confia.svg"
													alt="Confia"
												/>
											</p>
											<p className="centered-text">
												<button
													className="rm-button rm-button--primary rm-button--medium"
													type="button"
													onClick={backToProfile}
												>
													{tr('Go back to my profile')}
												</button>
											</p>
										</>
									)}
								</div>
							</div>

							<div className="checkout-card order-review">
								<div className="order-review-content">
									{pageType === 'form' && (
										<div className="shipping-details">
											<div className="card-content">
												<div className="shipping-details-heading">
													<h5>
														{tr('Your order number:')} {orderData?.reference}
													</h5>
												</div>
												{!isLoading && !isError && orderData && (
													<OrderSummaryComponent
														orderData={orderData}
														formatPrice={formatPrice}
														total={total}
														deposit={deposit}
														fulltotal={fulltotal}
														pageType={pageType}
													/>
												)}
												{isLoading && (
													<div className="is-loading">
														<p>{tr('Loading order data...')}</p>
														<div className="spinning-loader" />
													</div>
												)}
											</div>
										</div>
									)}
									{isError && (
										<>
											<p className="subtitle">
												<br />
												<strong>{tr('An error occured:')}</strong>
											</p>
											<p className="subtitle">{errorMessage}</p>
											<button
												className="rm-button rm-button--primary rm-button--medium"
												type="button"
												onClick={restartProcess}
											>
												{tr('Restart process')}
											</button>
										</>
									)}
									{pageType === 'form' &&
										!isLoading &&
										!isError &&
										orderData && ( // EMERGENCY 2023-09-27 -> remove form from production
											<>
												<p className="subtitle">
													{tr('We need your DNI/NIE to start your application process:')}
												</p>
												<div className="input-group full">
													<label>
														<input
															className={
																extraData.validForm || extraData.dni.length < 8
																	? ''
																	: 'error'
															}
															type="text"
															name="dni"
															id="shipping-name"
															value={extraData.dni}
															onChange={validateForm}
														/>
														<span>
															{extraData.type === 'dni'
																? 'DNI'
																: extraData.type === 'nie'
																? 'NIE'
																: 'DNI/NIE'}{' '}
															{extraData.validForm || extraData.dni.length < 8
																? ''
																: '(' + extraData.formError + ')'}
														</span>
													</label>
												</div>
												<div className="input-group full">
													<label htmlFor="reg_country">
														<CountrySelectComponent
															validateForm={validateForm}
															orderData={orderData}
															extraData={extraData}
														/>
														<span className="required">{tr('Country of residence')}</span>
													</label>
												</div>
											</>
										)}
									{pageType === 'form' &&
										!isLoading &&
										!isError &&
										orderData &&
										false && ( // EMERGENCY 2023-09-27 -> display message on production
											<>
												<div className="checkout-message error">
													<p>
														<strong>
															{tr(
																'We are sorry, the payment plan is not ready yet. It will be available within the next days. Sorry for the inconvenience'
															)}
														</strong>
													</p>
												</div>
											</>
										)}

									{(pageType === 'alreadypaid' || pageType === 'success') && orderData && (
										<>
											<div className="shipping-details-heading">
												<h5>
													{tr('Your order number:')} {orderData?.reference}
												</h5>
											</div>
											<OrderSummaryComponent
												orderData={orderData}
												formatPrice={formatPrice}
												total={total}
												deposit={deposit}
												fulltotal={fulltotal}
												pageType={pageType}
											/>
										</>
									)}
									<p>{confiaMessage}</p>
								</div>
								{pageType === 'form' && (
									<div id="purchase-area">
										<div className="member-final-price features-table">
											<div className="price-tag full-price">
												<div className="price price__retail"></div>
												<div className="price__total">
													{/*  
                          <span className="deposit-amount">
                            {tr('Total due:')} <strong> {formatPrice(total)}€ </strong>
                          </span>
                          
                           */}
													<span className="deposit-amount _outstanding-balance">
														{tr('Monthly fee:')}{' '}
														<strong>
															{' '}
															{formatPrice(total / 6)}€/{tr('month')}{' '}
														</strong>
													</span>
												</div>
											</div>
										</div>

										<button
											className="rm-button rm-button--primary rm-button--medium start-process-btn"
											disabled={
												!isLoading &&
												!isError &&
												orderData &&
												extraData.validForm &&
												extraData.dni != ''
													? false
													: true
											}
											type="button"
											onClick={startProcess}
										>
											{tr('Start process')}
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
				</section>
			</form>
		</div>
	)
}

export default App
