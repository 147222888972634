export function formatPrice(price) {
	return parseFloat(price).toFixed(2).replace('.', ',')
}

export function getQueryVars() {
	if (window.location.search === '') {
		return undefined
	}
	const vars = window.location.search.substring(1).split('&')
	const qvars = vars.reduce((acc, v) => {
		const [key, value] = v.split('=')
		return { ...acc, [key]: value }
	}, {})
	return qvars
}

export function validateDNI(dni) {
	let numero, letra, letr, type
	type = 'invalid'
	const expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/

	dni = dni.toUpperCase().replace(/\s/g, '').replace('-', '').replace('.', '')

	if (expresion_regular_dni.test(dni) === true) {
		numero = dni.substr(0, dni.length - 1)
		numero = numero.replace('X', 0)
		numero = numero.replace('Y', 1)
		numero = numero.replace('Z', 2)
		letr = dni.substr(dni.length - 1, 1)
		numero = numero % 23
		letra = 'TRWAGMYFPDXBNJZSQVHLCKET'
		letra = letra.substring(numero, numero + 1)
		if (letra !== letr) {
			return { valid: false, type, normalized: dni }
		} else {
			if (/^[XYZ]\d{7,8}[A-Z]$/.test(dni) === true) {
				type = 'nie'
			} else {
				type = 'dni'
			}
			return { valid: true, type, normalized: dni }
		}
	} else {
		return { valid: false, type, normalized: dni }
	}
}
export function getSeatImageUrl(orderData) {
	//console.log(orderData)
	let type = 'TA'
	let model = 'M00'
	let color = ''
	let plaque = ''
	if (orderData && orderData.details && orderData.details.order_items) {
		orderData.details.order_items.map((item) => {
			if (item[0].length === 2 && item[0].charAt(0) === 'T') {
				type = item[0]
			} else if (item[0].length === 7 && item[0].charAt(0) >= 'A' && item[0].charAt(0) <= 'D') {
				model = item[0].substring(1, 4)
				color = '_' + item[0].substring(4, 7)
			} else if (item[0].indexOf('Shield') >= 0) {
				if (item[0].indexOf('Silver') >= 0) {
					plaque = '_S'
				} else if (item[0].indexOf('Gold') >= 0) {
					plaque = '_G'
				} else if (item[0].indexOf('Black') >= 0) {
					plaque = '_B'
				}
			}
			return false
		})
	}
	//console.log({type, model, color, plaque})
	return `https://vafloc01.s3.amazonaws.com/WBStatic/site1104380/rm-custom-design/images/seats/thumbs/${type}_${model}${color}${plaque}.png`
}
const translations = {
	en: {
		TA: 'Seat - Style A',
		TB: 'Seat - Style B',
		TC: 'Seat - Style C',
		TD: 'Seat - Style D',
		TE: 'Seat - Style E',
	},
	es: {
		'Official Memorabilia Santiago Bernabéu Stadium': 'Memorabilia Oficial Estadio Santiago Bernabéu',
		Spain: 'España',
		'Invalid DNI/NIE': 'DNI/NIE no válido',
		'Validation error: Wrong query string. No order data could be found in the query string. Please try accessing this site from the your order profile page':
			'Error de validación: dirección incorrecta. No se han encontrado datos del pedido en la URL de acceso. Por favor, intente acceder desde su página de perfil',
		'We offer this special payment plan in agreement with Confía as our financing partner. You must follow their approval process to be granted to the payment plan.':
			'Ofrecemos este plan de pago con Confía como nuestro socio financiero. Deberás seguir su proceso de aprobación para acceder a la financiación de tu compra.',
		'Please follow the next steps:': 'Para ello, sigue los siguientes pasos: ',
		'Before you begin,': 'Antes de empezar,',
		'make sure you have your ID card/passport and a mobile phone where you can receive SMS notifications from Confía to confirm your identity.':
			'asegúrate de tener a mano tu DNI/NIE y un móvil en el que puedas recibir notificaciones SMS de Confía para confirmar tu identidad.',
		'Once your information has been verified and the credit has been approved':
			'Una vez comprobados tus datos y aprobado el crédito',
		'you will be redirected to the payment gateway to enter your credit card details. The validation process may take up to 15 minutes.':
			'serás redirigido a la pasarela de pago para introducir los datos de tu tarjeta de crédito. El proceso de validación puede durar hasta 15 minutos.',
		'The first of the 6 payments': 'Se cargará el primero de los 6 pagos',
		'for the outstanding amount of the order will be charged.': 'del importe pendiente del pedido.',
		'If you successfully complete the process,': 'Si finalizas el proceso con éxito,',
		'you will receive a confirmation email from Confia.': 'recibirás un email de confirmación de Confia.',
		'If your transaction is declined': 'Si tu operación es denegada',
		'you can try again with different user data or choose the one-time payment option.':
			'puedes volver a intentarlo con otros datos de usuario o elegir la opción de pago único.',
		'Important!': '¡Importante!',
		'Only available for Spanish residents and for orders up to 600€ including the shipping cost.':
			'Sólo disponible para residentes en España y para pedidos de hasta 600 euros incluyendo los gastos de envío.',
		'Confia might need up to 15 minutes to carry out the relevant checks.':
			'Confia puede tardar hasta 15 minutos en realizar las comprobaciones pertinentes.',
		'Approval depends on Confía risk calculation and is not related with Memorabilia or Real Madrid processes.':
			'La aprobación depende del cálculo de riesgos de Confía y no está relacionada con los procesos de Memorabilia o Real Madrid.',
		'(*) Only for Spanish residents: you will be charged a first 1/6 payment now. The remaining 5 payments will be executed automatically every month. You accept receiving this six charges.':
			'(*) Sólo para residentes en España: Confia cobrará directamente el primer pago. Los cinco pagos restantes se ejecutarán automáticamente cada mes. Iniciando este proceso aceptas el cargo de los seis pagos.',
		'Sorry, this order is being processed by Confía and we are still waiting for an answer. Please, wait up to 15 minutes before you try again.':
			'Lo sentimos, este pedido está siendo procesado por Confía y todavía estamos esperando una respuesta. Por favor, espera hasta 15 minutos antes de volver a intentarlo.',
		'If you do not get any communication from Confia within the next 15 minutes, you can start over the process.':
			'Si pasados 15 minutos no has recibido un aviso por parte de Confía, prueba a reiniciar el proceso.',
		'Your payment plan has been approved by': 'Tu plan de financiación ha sido aceptado por',
		'Hey! this order has been approved. Nothing else to do here!':
			'Este pedido ha sido aprobado. ¡No tienes que hacer nada más!',
		'Please check your order status in your profile page in the Memorabilia site. Keep in mind that status change might take up to 6 hours.':
			'Por favor, comprueba el estado de tu pedido en tu perfil de usuario. El cambio de estado de tu pedido puede tardar hasta 6 horas verse reflejado.',
		'There was a problem on the payment': 'Ha ocurrido un error con el pago',
		'Your payment plan has been rejected by': 'Tu plan de financiación ha sido denegado por',
		'We need your DNI/NIE to start your application process:': 'Necesitamos tu DNI/NIE para iniciar la solicitud:',
		'Country of residence': 'País de residencia',
		'Remember your order payment status might take a few hours to be updated. Please be patient.':
			'Recuerda que el estado de tu pedido puede tardar unas horas en actualizarse. Por favor, ten paciencia.',
		'Start process': 'Iniciar proceso',
		'Silver Shield': 'Escudo Plata',
		'Gold Shield': 'Escudo Oro',
		'Black Shield': 'Escudo Negro',
		'Go back to my profile': 'Volver a mi perfil',
		'Request a payment plan': 'Solicita un plan de pagos',
		'To request financing, follow these steps:': 'Para solicitar la financiación, sigue los siguientes pasos:',
		'Please check your order status in your profile in the Memorabilia site. Keep in mind that status change might take up to 6 hours.':
			'Comprueba el estado de tu pedido en tu perfil en la web de Memorabilia. Ten en cuenta que el cambio de estado puede tardar hasta 6 horas en verse reflejado en tu pedido.',
		'Your order number:': 'Tu número de pedido:',
		'Loading order data...': 'Cargando datos del pedido...',
		'An error occured:': 'Ha ocurrido un error:',
		'I agree with the terms and conditions': 'Acepto los términos y condiciones',
		month: 'mes',
		'Amount financed': 'Importe financiado',
		'Balance Received': 'Importe abonado',
		'Validation error: Please use a correct DNI/NIE number.':
			'Error de validación: Por favor, introduce un DNI/NIE correcto.',
		'Validation error: Financing is only available for Spanish residents.':
			'Error de validación: La financiación sólo está disponible para residentes en España.',
		'Restart process': 'Reiniciar proceso',
		'Commemorative Book': 'Libro conmemorativo',
		'Outstanding amount:': 'Importe a financiar:',
		'Outstanding amount': 'Importe a financiar',
		'Monthly fee:': 'Cuota mensual:',
		'No order data found in the query string': 'No se han encontrado datos del pedido en la petición',
		'Seat Details': 'Detalles del asiento',
		'Shipping Cost': 'Gastos de envío',
		'Deposit paid': 'Depósito pagado',
		'You already started your financing process': 'Ya has iniciado otro proceso de financiación',
		Deposit: 'Depósito',
		Paid: 'Pagado',
		Applied: 'aplicada',
		'All prices are VAT inclusive.': 'Todos los precios incluyen impuestos.',
		'Calculated at 21%.': '(21% de IVA)',
		'Order num.': 'Num. pedido',
		'Total price': 'Precio total',
		'Total due:': 'Importe pendiente:',
		'Total due': 'Importe pendiente',
		'Seat price': 'Precio del asiento',
		'Monthly Payment': 'Cuota mensual',
		'If you do not get any confirmation on the next 15 minutes, you can start over the process.':
			'Si no recibes ninguna confirmación en los próximos 15 minutos, puedes iniciar de nuevo el proceso.',
		'Congratulations!': '¡Enhorabuena!',
		'Link has expired.': 'El enlace ha caducado.',
		'Hash signature do not match.': 'La firma del enlace no coincide.',
		'Validation error: Order amount does not match with the actual order price. Please try again':
			'Error de validación: El importe del pedido no coincide con el precio real del pedido. Por favor, inténtalo de nuevo',
		'Validation error: Order reference could not be found in our platform. Please try again.':
			'Error de validación: No se ha encontrado la referencia del pedido en nuestra plataforma. Por favor, inténtalo de nuevo.',
		'Error trying to start the process. Order reference could not be found.':
			'Error al iniciar el proceso. No se ha encontrado la referencia del pedido.',
		Plaque: 'Placa identificativa',
		TA: 'Asiento - Estilo A',
		TB: 'Asiento - Estilo B',
		TC: 'Asiento - Estilo C',
		TD: 'Asiento - Estilo D',
		TE: 'Asiento - Estilo E',
		'assets/memorabilia-en_US.svg': 'assets/memorabilia_ES.png',
		'We are sorry, the payment plan is not ready yet. It will be available within the next days. Sorry for the inconvenience':
			'Lo sentimos, el plan de pagos aún no está listo. Estará disponible en los próximos días. Disculpa las molestias',
	},
}

const lang = window.navigator.language.substring(0, 2)
//const lang = 'es'
export function tr(key) {
	if (translations[lang] === undefined) {
	}
	if (translations[lang][key] === undefined) {
		return key
	}
	return translations[lang][key]
}
