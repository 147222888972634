import { useEffect, useState } from "react"
import { tr } from "../utils"
const RETURN_PROFILE_URL = process.env.REACT_APP_RETURN_PROFILE_URL

function HeaderComponent({ orderData }) {
	const [initials, setInitials] = useState("")
	useEffect(() => {
		if (orderData && orderData.details) {
			setInitials(
				(
					orderData.details.contact_first_name.charAt(0) + orderData.details.contact_last_name.charAt(0)
				).toUpperCase()
			)
		}
	}, [orderData])

	return (
		<header id="header" className="hide_header">
			<button className="header-link" title="Volver arriba">
				<a href={RETURN_PROFILE_URL} className="header-link" title="Volver arriba">
					<div id="header-title" className="header-title">
						<img src={tr("assets/memorabilia-en_US.svg")} alt="Memorabilia Real Madrid logo" />
						<span className="header-text">{tr("Official Memorabilia Santiago Bernabéu Stadium")}</span>
					</div>
				</a>
			</button>
			<div className="header-actions">
				<div className="login-user">
					<div className="data">
						{orderData && (
							<>
								<span className="user-name">
									{orderData.details.contact_first_name} {orderData.details.contact_last_name}
								</span>
								<span className="socio-number">
									{tr("Order num.")} {orderData.reference}
								</span>
							</>
						)}
					</div>
					<div className="user-avatar">
						<a href={RETURN_PROFILE_URL} className="profile-circle">
							{initials}
						</a>
					</div>
				</div>
			</div>
			<div id="header-background" className="header-background"></div>
		</header>
	)
}

export default HeaderComponent
